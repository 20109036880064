<template>
  <div class="home">
    <div class="home-header">
      <div class="home-header-box col">
        <div class="title">
          关于我们
        </div>
        <div class="tips">
          In aenean curabitur risus vivamus ante nulla nulla ut.<br />
          Tincidunt maecenas vulputate cursus amet. Enim
        </div>
      </div>
    </div>
    <div class="home-body">
      <div class="tips">
        Senectus sed at tempus cras.
      </div>
      <div class="title">
        关于我们
      </div>

      <div class="home-body-info flex">
        <img class="cover" src="~@/assets/images/intro.png" />
        <div class="main col">
          <div class="title">
            公司简介
          </div>
          <div class="content">
            深圳市安客睿思科技有限公司（简称Ancoris），专注于电商、外贸系统的研发，全线使用Java，采用Spring
            Cloud
            Alibaba解决方案及架构。研发的产品以技术新、价格低、授权宽松为特点深受用户的喜爱，以主流的微服务技术打造持续交付系统业务。成员来自于Cisco、Amazon、Google等各大互联网公司研发团队，自主创业。
            <br />秉承着“让天下没有难做的生意”的使命，用心做好产品。感谢您的关注与支持，深圳市安客睿思科技有限公司开发工作室会持续努力做出满足商业需求的产品！
          </div>
        </div>
      </div>

      <!-- <div class="tips">
        Senectus sed at tempus cras.
      </div>
      <div class="title">
        最新资讯
      </div>

      <div class="home-body-info flex">
        <img class="cover" src="~@/assets/images/half.png" />
        <div class="group col">
          <div class="list flex">
            <div class="text flex">
              广州小程序开发公司哪家好？
            </div>
            <svg-icon class="icon" svg-name="add" />
          </div>
          <div class="list flex">
            <div class="text">
              开发一个微信小程序要多少钱？
            </div>
            <svg-icon class="icon" svg-name="add" />
          </div>
          <div class="list flex">
            <div class="text"></div>
              开发一个微信小程序包含哪些费用呢？
            </div>
            <svg-icon class="icon" svg-name="add" />
          </div>
          <div class="list flex">
            <div class="text">
              最后，开发微信小程序极客云能为您做什么？
            </div>
            <svg-icon class="icon" svg-name="add" />
          </div>
          <div class="list flex">
            <div class="text">
              APP 软件开发外包流程是怎么样的？
            </div>
            <svg-icon class="icon" svg-name="add" />
          </div>
          <div class="more">
            查看更多 >
          </div>
        </div>
      </div> -->

      <div class="tips">
        Senectus sed at tempus cras.
      </div>
      <div class="title">
        公司地址
      </div>

      <div class="home-body-brand flex" style="margin-bottom: 120px;">
        <iframe class="address" src="/location.html"></iframe>
      </div>
    </div>
  </div>
</template>

<script>
// import SvgIcon from "../components/SvgIcon.vue";
// @ is an alias to /src

export default {
  // components: { SvgIcon },
  name: "About"
};
</script>

<style lang="stylus" scoped>
@import "~@/assets/stylus/common.styl"
.home
  &-header
    background-image url("../assets/images/bg-about.png")
  .address
    width 1200px
    height 272px
</style>
