<template>
  <div class="home">
    <div class="home-header">
      <div class="home-header-box col">
        <div class="title">
          各行各业解决方案
        </div>
        <div class="tips">
          In aenean curabitur risus vivamus ante nulla nulla ut.
          <br />Tincidunt maecenas vulputate cursus amet. Enim
        </div>
      </div>
    </div>
    <div class="home-body">
      <div class="tips">
        Senectus sed at tempus cras.
      </div>
      <div class="title">
        当前存在的问题
      </div>

      <div class="home-body-problem flex">
        <div class="home-body-problem-item">
          <div class="head">
            <svg-icon class="icon" svg-name="user"></svg-icon>
            <strong>用户体验和决策割裂</strong>
          </div>
          <div class="body">
            受制于阿里、腾讯系平台影响，无法打通天猫+京东+微信会员体系，品牌无法通过用户体验提升消费者决策
          </div>
        </div>
        <div class="home-body-problem-item">
          <div class="head">
            <svg-icon class="icon" svg-name="electron"></svg-icon>
            <strong>电商平台流量贵</strong>
          </div>
          <div class="body">
            电商的销售成本在销售价的50%左右。这还没有计算相关物流、仓储、退换货等服务以及中大型企业的广告赞助等费用。
          </div>
        </div>
        <div class="home-body-problem-item">
          <div class="head">
            <svg-icon class="icon" svg-name="data"></svg-icon>
            <strong>消费者数据无法打通</strong>
          </div>
          <div class="body">
            电商平台用户数据、订单数据等不能集中管理和分析，无法通过品牌自有用户数据有效指导营销行为
          </div>
        </div>
        <div class="home-body-problem-item">
          <div class="head">
            <svg-icon class="icon" svg-name="low"></svg-icon>
            <strong>营销场景化能力不足</strong>
          </div>
          <div class="body">
            传统基于浏览和搜索的触达方式，消费者无法获取优质的场景营销体验，无法有效参与社交分享和裂变等新式营销
          </div>
        </div>
      </div>

      <div class="tips">
        Senectus sed at tempus cras.
      </div>
      <div class="title">
        如何解决这些问题
      </div>
      <div class="home-body-brand" style="margin-bottom: 120px">
        <img src="@/assets/images/kyy.png" />
        <img src="@/assets/images/wechatCrm.png" />
        <img src="@/assets/images/amazonCrm.png" />
      </div>

      <div class="home-body-numGroup flex">
        <div class="home-body-numGroup-box col">
          <img src="@/assets/images/stable.png" />
          <div class="title">
            平台运行稳定
          </div>
          <div class="tips">
            连续稳定运行超10000小时 <br />
            无故障近8760小时
          </div>
        </div>

        <div class="home-body-numGroup-box col">
          <img src="@/assets/images/support.png" />
          <div class="title">
            大并发支持
          </div>
          <div class="tips">
            无故障支持并发生成峰值 <br />
            200条/秒
          </div>
        </div>

        <div class="home-body-numGroup-box col">
          <img src="@/assets/images/fast.png" />
          <div class="title">
            跳转速度快
          </div>
          <div class="tips">
            无故障支持并发跳转峰值<br />
            500次/秒
          </div>
        </div>

        <div class="home-body-numGroup-box col">
          <img src="@/assets/images/error.png" />
          <div class="title">
            防误拦屏蔽
          </div>
          <div class="tips">
            多重链接防护技术专业保障 <br />
            流量平台风控合作
          </div>
        </div>

        <div class="home-body-numGroup-box col">
          <img src="@/assets/images/connect.png" />
          <div class="title">
            全国连通性好
          </div>
          <div class="tips">
            技术团队强悍，系统设计先进 <br />
            大数据技术优势明显
          </div>
        </div>
      </div>

      <div class="tips">
        Senectus sed at tempus cras.
      </div>
      <div class="title">
        选择我们的原因
      </div>

      <div class="home-body-cardGroup flex">
        <div class="home-body-cardGroup-card col">
          <div class="title flex">
            <svg-icon class="icon" svg-name="flow"></svg-icon>
            吸粉引流，高效整合
          </div>
          <div class="text">
            整合线上线下渠道流量，统计引流效果数据，构建私域流量池
          </div>
        </div>

        <div class="home-body-cardGroup-card middle col">
          <div class="title flex">
            <svg-icon class="icon" svg-name="mark"></svg-icon>
            标签分析，精细运营
          </div>
          <div class="text">
            整合线上线下渠道流量，统计引流效果数据，构建私域流量池
          </div>
        </div>

        <div class="home-body-cardGroup-card col">
          <div class="title flex">
            <svg-icon class="icon" svg-name="change"></svg-icon>
            内容营销，促进转化
          </div>
          <div class="text">
            提供11种内容营销工具和营销活动。多触点触达客户，促进商机转化
          </div>
        </div>

        <div class="home-body-cardGroup-card col">
          <div class="title flex">
            <svg-icon class="icon" svg-name="wechat"></svg-icon>
            微信CRM，赋能销售
          </div>
          <div class="text">
            将CRM与企业微信融为一体，帮助企业提高销售效率
          </div>
        </div>

        <div class="home-body-cardGroup-card middle col">
          <div class="title flex">
            <svg-icon class="icon" svg-name="task"></svg-icon>
            任务下发，提效管理
          </div>
          <div class="text">
            提供多维度营销任务系统及销售排行榜，提效销售管理
          </div>
        </div>

        <div class="home-body-cardGroup-card col">
          <div class="title flex">
            <svg-icon class="icon" svg-name="private"></svg-icon>
            私有部署，数据无忧
          </div>
          <div class="text">
            提供完整，安全的私有化部署方案，打造真正属于企业的私域流量平台
          </div>
        </div>
      </div>

      <div class="tips">
        Senectus sed at tempus cras.
      </div>
      <div class="title">
        版本差异介绍
      </div>

      <div class="home-body-cardGroup price flex">
        <div class="home-body-cardGroup-card price">
          <div class="head flex">
            <svg-icon class="icon" svgName="level-base"></svg-icon>
            基础版
          </div>
          <div class="tips">
            能满足基本需求
          </div>
          <div class="item">
            多店铺管理
            <svg-icon class="icon" svgName="ok"></svg-icon>
          </div>
          <div class="item">
            微信营销活动
            <svg-icon class="icon" svgName="ok"></svg-icon>
          </div>
          <div class="item">
            私域用户统一管理
            <svg-icon class="icon" svgName="no"></svg-icon>
          </div>
          <div class="item">
            客户标签及历史轨迹
            <svg-icon class="icon" svgName="no"></svg-icon>
          </div>
          <div class="item">
            订单一键发货
            <svg-icon class="icon" svgName="no"></svg-icon>
          </div>
          <div class="num">
            <span>￥</span><strong>499</strong><span>/年</span>
          </div>
          <div @click="$emit('contact')" class="order isBtn flex">
            订购
          </div>
        </div>

        <div class="home-body-cardGroup-card price middle">
          <div class="head flex">
            <svg-icon class="icon" svgName="level-pro"></svg-icon>
            中级版
          </div>
          <div class="tips">
            能满足基本需求
          </div>
          <div class="item">
            多店铺管理
            <svg-icon class="icon" svgName="ok"></svg-icon>
          </div>
          <div class="item">
            微信营销活动
            <svg-icon class="icon" svgName="ok"></svg-icon>
          </div>
          <div class="item">
            私域用户统一管理
            <svg-icon class="icon" svgName="ok"></svg-icon>
          </div>
          <div class="item">
            客户标签及历史轨迹
            <svg-icon class="icon" svgName="no"></svg-icon>
          </div>
          <div class="item">
            订单一键发货
            <svg-icon class="icon" svgName="no"></svg-icon>
          </div>
          <div class="num">
            <span>￥</span><strong>999</strong><span>/年</span>
          </div>
          <div @click="$emit('contact')" class="order isBtn flex">
            订购
          </div>
        </div>

        <div class="home-body-cardGroup-card price">
          <div class="head flex">
            <svg-icon class="icon" svgName="level-plus"></svg-icon>
            高级版
          </div>
          <div class="tips">
            能满足基本需求
          </div>
          <div class="item">
            多店铺管理
            <svg-icon class="icon" svgName="ok"></svg-icon>
          </div>
          <div class="item">
            微信营销活动
            <svg-icon class="icon" svgName="ok"></svg-icon>
          </div>
          <div class="item">
            私域用户统一管理
            <svg-icon class="icon" svgName="ok"></svg-icon>
          </div>
          <div class="item">
            客户标签及历史轨迹
            <svg-icon class="icon" svgName="ok"></svg-icon>
          </div>
          <div class="item">
            订单一键发货
            <svg-icon class="icon" svgName="ok"></svg-icon>
          </div>
          <div class="num">
            <span>￥</span><strong>1999</strong><span>/年</span>
          </div>
          <div @click="$emit('contact')" class="order isBtn flex">
            订购
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from "../components/SvgIcon.vue";
// @ is an alias to /src

export default {
  components: { SvgIcon },
  name: "Solution"
};
</script>

<style lang="stylus" scoped>
@import "~@/assets/stylus/common.styl"
.home
  &-header
    background-image url("../assets/images/bg-solution.png")
  &-body
    &-cardGroup
      &-card
        height 178px
</style>
